import { useEffect, useState } from "react";
import { CtxAppParams } from "./Contexts/ctxAppParams";
import { QueryClient, QueryClientProvider } from "react-query";
import useApp from "./Components/useApp";
import Rotas from "./routes";
import useAutenticacao from "./Components/useAuth";

const queryDefault = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			cacheTime: 300000, // Cache 5 miuntos
			retryDelay: 5000, // 5 Segundos para dar um retry em caso de erro
		},
	},
});

function App() {
	const Aplicativo = useApp();
	const {getToken} = useAutenticacao()
	const [SessaoExpirada, setSessaoExpirada] = useState<boolean>(false);
	const [Token, setToken] = useState<string | null | undefined>(getToken);

	useEffect(() => {
		if(SessaoExpirada) {
			alert('Sessão expirada');
            Aplicativo.Deslogar();
		}
	}, [SessaoExpirada]); // eslint-disable-line

	return (
		<CtxAppParams.Provider value={{
			SessaoExpirada, setSessaoExpirada,
			Token, setToken
		}}>
			<QueryClientProvider client={queryDefault}>
				<Rotas />
			</QueryClientProvider>
		</CtxAppParams.Provider>
	);
}

export default App;
