import { useMemo } from "react";
import { IReactTable_Grid } from "../Interfaces";

const useApp = () => {
    const deslogar = () => {
        sessionStorage.removeItem('token');
        localStorage.removeItem('token');
        localStorage.removeItem('celular');

        const refreshCacheAndReload = () => {
            if (caches) {
                // Service worker cache should be cleared with caches.delete()
                caches.keys().then((names) => {
                    for (const name of names) {
                        caches.delete(name);
                    }
                });
            };

            if ('serviceWorker' in navigator && 'ready' in navigator.serviceWorker) {
                navigator.serviceWorker.ready.then((registration) => {
                    registration.update();
                })
                .catch((error) => {
                    console.error('Erro ao atualizar aplicação: ', error);
                });
            }

            window.location.reload();
        };

        refreshCacheAndReload();
    };

    const grid = useMemo<IReactTable_Grid>(() => [
        {
            id: "chave",
            accessor: "CHAVE"
        },
        {
            id: "valor",
            accessor: "VALOR"
        }        
    ], []);

    return {
        Deslogar: deslogar,
        Grid: grid
    }
}

export default useApp;