import { useContext } from 'react';
import { ILogin } from '../Interfaces';
import { CtxAppParams } from '../Contexts/ctxAppParams';

const useAutenticacao = () : IUseAutenticacao => {
    const AppParams = useContext(CtxAppParams);

    const getToken = () : string | undefined | null => {
        const tokenString = localStorage.getItem('token');
        return tokenString;
    };

    const saveToken = (userToken : ILogin) => {
        const objLogin = JSON.parse(JSON.stringify(userToken));
        
        if (typeof objLogin.token !== 'undefined') {
            localStorage.setItem('token', userToken.token!);
            localStorage.setItem('celular', userToken.credenciais!.CELULAR);
            AppParams.setToken(userToken.token!);
        }
    };

    return {
        setToken: saveToken,
        getToken,
    };
}

export interface IUseAutenticacao {
    setToken            : (userToken: ILogin) => void;
    getToken            : () => string | undefined | null;
}

export default useAutenticacao;