import { Route, Routes, BrowserRouter } from "react-router-dom";
import { useContext } from "react";

import { CtxAppParams } from "./Contexts/ctxAppParams";
import Autenticacao from "./Pages/Autenticacao";
import Protocolo from "./Pages/Protocolo";
import ProtocoloLista from "./Pages/Protocolo/lista";
import ProtocoloCadastro from "./Pages/Protocolo/cadastro";

const Rotas = () => {
    const AppParams = useContext(CtxAppParams);

    if(!AppParams.Token!) {
        return (
            <Autenticacao />
        )
    } else {
        return (
            <BrowserRouter>            
                <div style={{marginBottom: '9vh'}}>
                    <Routes>
                        <Route path={'/'} element={<Protocolo />} >
                            <Route index element={<ProtocoloLista />} />
    
                            <Route path={"cadastro/:cdProtocolo?"} element={<ProtocoloCadastro />} />
                        </Route>
                    </Routes>
                </div>
            </BrowserRouter>
        )
    }
}

export default Rotas;