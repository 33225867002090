import { Formik } from "formik";
import useUsuariosController from "../Controllers/Usuarios"
import { Button, Container, Form, InputGroup } from 'react-bootstrap';
import { ILogin } from "../Interfaces";
import useMask from "../Components/useMask";
import {useState} from "react";
import { every } from "lodash";

const Autenticacao = () => {
    const Usuarios = useUsuariosController('usuarioLogin', false, false);
    const formatter = useMask();
    const [confirmado, setConfirmado] = useState<boolean>(false);

    return (
        <Container className={"verticalCenterFull"} style={{height: '100vh'}}>
            <img src="./logo512.png" style={{height: '10rem'}}></img>
            <h3 className="text-center mt-3 w-100">Autenticação</h3>

            <Formik
                // validationSchema={!consultouEmpresa ? schemaConsultaEmpresa : schemaLogin}
                onSubmit={(e : ILogin) => {
                    const dados = Object.assign({}, e);
                    dados.CELULAR = formatter.getPhone(dados.CELULAR);

                    Usuarios.Login.mutate(dados);
                }}
                initialValues={{
                    CELULAR: '',
                    SENHA: '',
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mt-3">
                            <Form.Label>Celular:</Form.Label>
                            {/* <BadgeValidacao validacao={errors.CELULAR} confirmado={confirmado} /> */}
                            <InputGroup>
                                <InputGroup.Text id="cdPais">+55</InputGroup.Text>
                                <Form.Control 
                                    name={'TELEFONE'}
                                    type={'text'}
                                    value={values.CELULAR}
                                    onChange={ e => setFieldValue("CELULAR", formatter.setMaskPhone(e.target.value)) }
                                    isInvalid={every([errors.CELULAR, confirmado])}
                                    placeholder="Celular..." 
                                    size="lg"
                                />
                            </InputGroup>
                        </Form.Group>                    

                        <Form.Group className="mt-3">
                            <Form.Label>Senha:</Form.Label>
                            {/* <BadgeValidacao validacao={errors.SENHA} confirmado={confirmado} /> */}
                            <Form.Control
                                name={'SENHA'}
                                type={'password'}
                                value={values.SENHA}
                                onChange={handleChange}
                                isInvalid={every([errors.SENHA, confirmado])}
                                placeholder="Senha..." 
                                size="lg"
                            />
                        </Form.Group>

                        <div className="w-100 d-flex justify-content-end">
                            <Button 
                                className="mt-3"
                                variant="success" 
                                size="lg" 
                                type="submit" 
                                onClick={() => {
                                    setConfirmado(true);
                                }}
                            >
                                Login
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default Autenticacao;