import { UseMutationResult, useMutation } from "react-query";
import useAPI from "../Components/useAPI";
import { DeepPartial, ILogin } from "../Interfaces";
import useAutenticacao from "../Components/useAuth";

const useUsuariosController = (updateQueryKey: string, isArray: boolean = true, enableQuery: boolean = false) : IUseUsuariosController => {
    const {setToken} = useAutenticacao();
    const API = useAPI();

    // const primaryKey = 'CELULAR';
    const endpoint = 'usuarios';

    const mutLogin = useMutation(
        (data : DeepPartial<ILogin>) => 
            API.post(`${endpoint}/login`, data, true)
        , {
            onSuccess: (ret: ILogin) => {
                setToken(ret);
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    return {
        Login: mutLogin
    }
}

export interface IUseUsuariosController {
    Login      : UseMutationResult<ILogin, any, DeepPartial<ILogin>, unknown>
}

export default useUsuariosController;