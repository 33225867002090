const useControllers = () => {

    const updateObjectByKey = (oldData: any, newData: any, keyData: string, isArray: boolean) => {
        if (isArray) {
            return oldData.map((object: any) => {
                if (object[keyData] === newData[keyData]) {
                    return {
                        ...object,
                        ...newData,
                    };
                }
                return object;
            });
        } else {
            if (oldData[keyData] === newData[keyData]) {
                return {
                    ...oldData,
                    ...newData,
                };
            } else {
                return oldData;
            }
        }
    };

    

    const deleteObjectByProperties = <T extends object>(
        dataArray: T[],
        properties: Partial<T>
    ): T[] => {
        return dataArray.filter((data) => {
            for (const key in properties) {
                if (data[key] !== properties[key]) {
                    return true;
                }
            }

            return false;
        });
    };
    
    const addObjectToArray = <T extends object>(
        dataArray: T[],
        newObject: T
    ): T[] => {
        return [...dataArray, newObject];
    };

    return {
        Update: updateObjectByKey,
        Delete: deleteObjectByProperties,
        Create: addObjectToArray,
    }
}

export default useControllers;