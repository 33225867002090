import useProtocoloController, { IUseProtocoloController } from "../../../Controllers/Protocolo"
import { useMemo, useCallback, useEffect, useState } from "react";
import { DeepPartial, EMode, IProtocolo, IProtocolos, IReactTable_Grid } from "../../../Interfaces";
import { Badge } from "react-bootstrap";
import useMask from "../../../Components/useMask";
import { useNavigate } from "react-router-dom";
import Report_CapaPendencia from "../../../Reports/capaPendencia";

interface filtro {
    tipoFiltro: string;
    pesquisa: string;
}

const useProtocolo = (cdProtocolo: string | undefined) : IUseProtocolo => {
    const Controller = useProtocoloController('protocolo', true, true);
    const formatter = useMask();
    const navigate = useNavigate();
    const Mode : EMode = cdProtocolo ? EMode.Edit : EMode.Include;
    const [filtro, setFiltro] = useState<filtro>({tipoFiltro: 'Numero', pesquisa: ''});

    const dsItem = useMemo<IProtocolo>(() => {
        if (Controller.Buscar.isSuccess) {
            return Controller.Buscar.data
        } else {
            return {} as IProtocolo
        }
    }, [Controller.Buscar])

    const Imprimir = () => {
        Report_CapaPendencia(dsItem)
    }

    const dsLista = useMemo<IProtocolos>(() => {
        if (Controller.Listar.isFetched && Controller.Listar.isSuccess) {
            return Controller.Listar.data && Controller.Listar.data.filter((item) => {
                if (filtro.tipoFiltro === 'Numero' && filtro.pesquisa !== '') {
                    return String(item.NUMERO) === filtro.pesquisa
                } else if (filtro.tipoFiltro === 'Convenio' && filtro.pesquisa !== '') {
                    return formatter.getNormalized(item.CONVENIO!?.toLowerCase() ?? "").indexOf(formatter.getNormalized(filtro.pesquisa).toLowerCase()) !== -1
                } else if (filtro.tipoFiltro === 'Paciente' && filtro.pesquisa !== '') {
                    return formatter.getNormalized(item.PACIENTE!?.toLowerCase() ?? "").indexOf(formatter.getNormalized(filtro.pesquisa).toLowerCase()) !== -1
                } else if (filtro.tipoFiltro === 'Data Entrega' && filtro.pesquisa !== '') {
                    return formatter.setDate(new Date(item.DT_ENTREGA!), {year: "numeric", month: "2-digit", day:"2-digit"}, false, true).slice(0, 10) === formatter.setDate(new Date(filtro.pesquisa), {year: "numeric", month: "2-digit", day:"2-digit"}, true, true).slice(0, 10)
                } else {
                    return item
                }
            })
        } else {
            return [{} as IProtocolo]
        }
    }, [Controller.Listar, filtro, formatter]);

    const Buscar = useCallback((Protocolo: DeepPartial<IProtocolo>) => {
        const Codigo = Protocolo.CD_PROTOCOLO || Number(cdProtocolo);
        
        if (Codigo === -1 || Codigo === undefined ) {
            Controller.Buscar.reset();
        }

        if (dsItem.CD_PROTOCOLO !== Codigo) {
            Controller.Buscar.mutate({CD_PROTOCOLO: Codigo});
        }
    }, [Controller.Buscar, dsItem.CD_PROTOCOLO, cdProtocolo]);

    const gridLista = useMemo<IReactTable_Grid>(() => [
        {
            id: "CD_PROTOCOLO",
            Header: "Protocolo",
            accessor: "CD_PROTOCOLO"
        },
        {
            id: "NUMERO",
            Header: "Número",
            accessor: "NUMERO"
        },
        {
            id: "PACIENTE",
            Header: "Paciente",
            accessor: "PACIENTE"
        },
        {
            id: "PENDENCIA",
            Header: "Pendencias",
            Cell: (row: any) : any => {
                const dados : IProtocolo = row.row.original;

                return formatter.setOverflow(dados.PENDENCIA ?? '', 50);
            }
        },
        {
            id: "CONVENIO",
            Header: "Convenio",
            accessor: "CONVENIO"
        },
        {
            id: "DATAS",
            Header: "Datas",
            Cell: (row: any) : any => {
                const dados : IProtocolo = row.row.original;

                return (
                    <div className="d-flex w-100 align-items-center justify-content-around">
                        <Badge pill bg="dark">Entrada: {formatter.setDate(new Date(dados.DT_ENTRADA), {day: "2-digit", month: "2-digit", year: "numeric"})}</Badge>
                        <Badge pill bg="dark">Saída: {formatter.setDate(new Date(dados.DT_SAIDA), {day: "2-digit", month: "2-digit", year: "numeric"})}</Badge>
                    </div>
                )
            }
        },
        {
            id: "DT_ENTREGA",
            Header: "Entrega",
            Cell: (row: any) : any => {
                const dados : IProtocolo = row.row.original;

                return formatter.setDate(new Date(dados.DT_ENTREGA!), {day: "2-digit", month: "2-digit", year: "numeric"})
            }
        },
    ], [formatter]);

    const Abrir = useCallback((Protocolo: DeepPartial<IProtocolo>) => {
        if (Protocolo.CD_PROTOCOLO) {
            Buscar(Protocolo);
            navigate(`/cadastro/${Protocolo.CD_PROTOCOLO}`);
        } else {
            Controller.Buscar.reset();
            navigate(`/cadastro/`);
        }
    }, [Buscar, navigate, Controller.Buscar]);

    useEffect(() => {
        if ((cdProtocolo !== undefined) && Controller.Buscar.isIdle) {
            // console.log('buscar protoocolo')
            Buscar({CD_PROTOCOLO: Number(cdProtocolo!)})
        }
    }, [cdProtocolo, Buscar, Controller.Buscar]); 

    return {
        Controller  : Controller,
        Lista       : dsLista,
        Item        : dsItem,
        Buscar      : Buscar,
        Grid        : gridLista,
        Abrir       : Abrir,
        Mode        : Mode,
        Imprimir    : Imprimir,
        Filtro      : filtro,
        setFiltro   : setFiltro
    }
}

export interface IUseProtocolo {
    Controller  : IUseProtocoloController;
    Lista       : IProtocolos;
    Item        : IProtocolo;
    Buscar      : (Protocolo: DeepPartial<IProtocolo>) => void;
    Grid        : IReactTable_Grid;
    Abrir       : (Protocolo: DeepPartial<IProtocolo>) => void;
    Mode        : EMode;
    Imprimir    : () => void;
    Filtro      : filtro;
    setFiltro   : React.Dispatch<React.SetStateAction<filtro>>;
}

export default useProtocolo;