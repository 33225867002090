import { useNavigate, useOutletContext } from "react-router-dom";
import { IUseProtocolo } from "./Hook/useProtocolo";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import { EMode, IProtocolo, schemaProtocolo } from "../../Interfaces";
import BadgeValidacao from "../../Components/BadgeValidacao";
import { useState } from "react";
import { every } from "lodash";
import useMask from "../../Components/useMask";

const ProtocoloCadastro = () => {
    const Protocolo = useOutletContext<IUseProtocolo>();
    const navigate = useNavigate();
    const [confirmado, setConfirmado] = useState<boolean>(false);
    const formatter = useMask();

    return (
        <Container className="verticalCenter mt-3">
            <Formik
                enableReinitialize
                validationSchema={schemaProtocolo}
                onSubmit={(dados : IProtocolo) => {
                    if (Protocolo.Mode === EMode.Edit) {
                        if (window.confirm(`Realmente deseja excluir o protocolo número ${dados.NUMERO}?. A ação não poderá ser desfeita!`)) {
                            Protocolo.Controller.Deletar.mutateAsync(dados)
                            .then(() => {
                                setConfirmado(false);
                                navigate("/");
                            })
                        }                        
                    } else {
                        Protocolo.Controller.Cadastrar.mutateAsync(dados)
                        .then((ret) => {
                            setConfirmado(false);
                            Protocolo.Abrir({CD_PROTOCOLO: ret['sucesso']['CD_PROTOCOLO']})
                        })
                    }
                }}
                initialValues={{
                    CD_PROTOCOLO        : Protocolo.Item.CD_PROTOCOLO        ?? undefined,
                    NUMERO              : Protocolo.Item.NUMERO              ?? '',
                    PACIENTE            : Protocolo.Item.PACIENTE            ?? '',
                    CONVENIO            : Protocolo.Item.CONVENIO            ?? '',
                    PENDENCIA           : Protocolo.Item.PENDENCIA           ?? '',
                    DT_ENTRADA          : Protocolo.Item.DT_ENTRADA          ?? undefined,
                    DT_SAIDA            : Protocolo.Item.DT_SAIDA            ?? formatter.setDate(new Date(), {year: "numeric", month: "2-digit", day:"2-digit"}, false, true).slice(0, 10),
                    USUARIO             : Protocolo.Item.USUARIO             ?? localStorage.getItem('celular'),
                }}
                >
                {({
                    handleSubmit,
                    handleChange,
                    setFieldValue,
                    values,
                    errors,
                }) => (
                    <Form onSubmit={handleSubmit} onReset={() => Protocolo.Abrir({CD_PROTOCOLO: undefined})}>    
                        <div className="w-100 d-flex justify-content-between">
                            <Button variant="outline-dark" onClick={() => navigate('/')}>Voltar</Button>
                            <Form.Control 
                                style={{maxWidth: '30%'}}
                                name={'CD_PROTOCOLO'}
                                type={'number'}
                                value={values.CD_PROTOCOLO ?? ""}
                                disabled
                            />
                            {
                                Protocolo.Mode === EMode.Edit
                                ? <>
                                    <Button variant="outline-primary" onClick={() => Protocolo.Imprimir()}>Imprimir</Button>
                                    <Button variant="danger" type="submit">Excluir</Button>
                                </>
                                : <Button variant="success" type="submit" onClick={() => setConfirmado(true)}>Salvar</Button>
                            }
                            
                        </div>

                        <Row>
                            <Col xs={6}>
                                <Form.Group className="mt-3">
                                    <Form.Label>{`Número:`}</Form.Label>
                                    <BadgeValidacao validacao={errors.NUMERO} confirmado={confirmado} />
                                    <Form.Control 
                                        name={'NUMERO'}
                                        type={'number'}
                                        value={values.NUMERO ?? ""}
                                        onChange={handleChange}
                                        disabled={Protocolo.Mode === EMode.Edit}
                                        isInvalid={every([errors.NUMERO, confirmado])}
                                        placeholder={"Número..."}
                                    />
                                </Form.Group>  
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Convenio:</Form.Label>
                                    <BadgeValidacao validacao={errors.CONVENIO} confirmado={confirmado} />
                                    <Form.Select 
                                        name={'CONVENIO'}
                                        value={values.CONVENIO ?? ""}
                                        onChange={handleChange}
                                        disabled={Protocolo.Mode === EMode.Edit}
                                        isInvalid={every([errors.CONVENIO, confirmado])}
                                        placeholder={"Convenio..."}
                                    >
                                        <option value="" title=""></option>
                                        <option value="AERONÁUTICA" title="AERONÁUTICA">AERONÁUTICA</option>
                                        <option value="AMAFRERJ" title="AMAFRERJ">AMAFRERJ</option>
                                        <option value="AMIL" title="AMIL">AMIL</option>
                                        <option value="ASSIM SAUDE" title="ASSIM SAUDE">ASSIM SAUDE</option>
                                        <option value="BRADESCO" title="BRADESCO">BRADESCO</option>
                                        <option value="CASSI" title="CASSI">CASSI</option>
                                        <option value="CBMERJ" title="CBMERJ">CBMERJ</option>
                                        <option value="GAMA" title="GAMA">GAMA</option>
                                        <option value="GOLDEN CROSS" title="GOLDEN CROSS">GOLDEN CROSS</option>
                                        <option value="IPALERJ" title="IPALERJ">IPALERJ</option>
                                        <option value="LIFE" title="LIFE">LIFE</option>
                                        <option value="MEDISERVICE" title="MEDISERVICE">MEDISERVICE</option>
                                        <option value="PASA" title="PASA">PASA</option>
                                        <option value="PETROBRAS" title="PETROBRAS">PETROBRAS</option>
                                        <option value="PMERJ" title="PMERJ">PMERJ</option>
                                        <option value="POSTAL SAUDE" title="POSTAL SAUDE">POSTAL SAUDE</option>
                                        <option value="REAL GRANDEZA" title="REAL GRANDEZA">REAL GRANDEZA</option>
                                        <option value="SULAMERICA" title="SULAMERICA">SULAMERICA</option>
                                        <option value="VALE" title="VALE">VALE</option>
                                    </Form.Select>
                                </Form.Group>  
                            </Col>
                        </Row>
                        <Row>
                            <Form.Group className="mt-3">
                                <Form.Label>Paciente:</Form.Label>
                                <BadgeValidacao validacao={errors.PACIENTE} confirmado={confirmado} />
                                <Form.Control 
                                    name={'PACIENTE'}
                                    type={'text'}
                                    value={values.PACIENTE ?? ""}
                                    onChange={handleChange}
                                    disabled={Protocolo.Mode === EMode.Edit}
                                    isInvalid={every([errors.PACIENTE, confirmado])}
                                    placeholder={"Paciente..."}
                                    onBlur={() => setFieldValue("PACIENTE", values.PACIENTE.toUpperCase())}
                                />
                            </Form.Group>  
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Data de Entrada:</Form.Label>
                                    <BadgeValidacao validacao={errors.DT_ENTRADA} confirmado={confirmado} />
                                    <Form.Control 
                                        name={'DT_ENTRADA'}
                                        type={'date'}
                                        value={values.DT_ENTRADA as string}
                                        onChange={handleChange}
                                        disabled={Protocolo.Mode === EMode.Edit}
                                        isInvalid={every([errors.DT_ENTRADA, confirmado])}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={6}>
                                <Form.Group className="mt-3">
                                    <Form.Label>Data de Alta:</Form.Label>
                                    <BadgeValidacao validacao={errors.DT_SAIDA} confirmado={confirmado} />
                                    <Form.Control 
                                        name={'DT_SAIDA'}
                                        type={'date'}
                                        value={values.DT_SAIDA as string}
                                        onChange={handleChange}
                                        disabled={Protocolo.Mode === EMode.Edit}
                                        isInvalid={every([errors.DT_SAIDA, confirmado])}
                                    />
                                </Form.Group>
                            </Col>  
                        </Row>
                        <Row>
                            <Form.Group className="mt-3">
                                <Form.Label>Pendencia:</Form.Label>
                                <BadgeValidacao validacao={errors.PENDENCIA} confirmado={confirmado} />
                                <Form.Control 
                                    name={'PENDENCIA'}
                                    type={'text'}
                                    as={'textarea'}
                                    value={values.PENDENCIA ?? ""}
                                    onChange={handleChange}
                                    disabled={Protocolo.Mode === EMode.Edit}
                                    isInvalid={every([errors.PENDENCIA, confirmado])}
                                    placeholder={"Pendencias..."}
                                    onBlur={() => setFieldValue("PENDENCIA", values.PENDENCIA.toUpperCase())}
                                />
                            </Form.Group>  
                        </Row>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default ProtocoloCadastro;