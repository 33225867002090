import { createContext } from 'react';

export interface ICtxAppParams {
    SessaoExpirada: boolean;
    setSessaoExpirada: React.Dispatch<React.SetStateAction<boolean>>;

    Token: string | null | undefined;
    setToken: React.Dispatch<React.SetStateAction<string | null | undefined>>;
}

export const CtxAppParams = createContext<ICtxAppParams>({
    SessaoExpirada: false,
    Token: undefined
} as ICtxAppParams);