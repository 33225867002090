import { useContext } from "react";
import { CtxAppParams } from "../Contexts/ctxAppParams";

const useAPI = () => {
    const { setSessaoExpirada } = useContext(CtxAppParams);

    enum HttpMethod {
        Post    = "POST",
        Get     = "GET",
        Put     = "PUT",
        Delete  = "DELETE"
    };

    const generateUrlParams = (method: HttpMethod, values: any) : string => {
        if (method === HttpMethod.Get || method === HttpMethod.Delete) {
            if ((values !== '')) {
                return new URLSearchParams(values).toString();
            } else {
                return '';
            };  
        } else { return '' };
    };

    const verifySessionExpired = (returnedStr: string) : boolean => {
        const retorno = JSON.parse(JSON.stringify(returnedStr));
        if (retorno["dados"] === 'token invalido.') {
            return true;
        } else {
            return false;
        }
    };

    const post = async (endpoint: string, values: any, encodeValues: boolean = true, json: boolean = true, customHeaders?: object) => {
        return call(HttpMethod.Post, endpoint, values, encodeValues, json, customHeaders);
    };

    const get = async (endpoint: string, values: any, encodeValues: boolean = true, customHeaders?: object) => {
        return call(HttpMethod.Get, endpoint, values, encodeValues, true, customHeaders);
    };

    const put = async (endpoint: string, values: any, encodeValues: boolean = true) => {
        return call(HttpMethod.Put, endpoint, values, encodeValues);
    };

    const del = async (endpoint: string, values: any, encodeValues: boolean = true) => {
        return call(HttpMethod.Delete, endpoint, values, encodeValues);
    };

    // const getEmpresa = () : string => {
    //     if (localStorage.getItem('params')) {
    //         return JSON.parse(localStorage.getItem('params')!).CD_EMPRESA
    //     } else if (sessionStorage.getItem('EMPRESA')) {
    //         return sessionStorage.getItem('EMPRESA')!
    //     } else {
    //         return ''
    //     }
    // }

    const call = async (
        method: HttpMethod, 
        endpoint: string, 
        values: any, 
        encodeValues: boolean, 
        json: boolean = true,
        customHeaders?: object
    ) => {
        let errors = false;
        let returnValue;
        let paramUrl = encodeValues ? generateUrlParams(method, values) : values;
        const token = localStorage.getItem('token');    

        if (!navigator.onLine) {
            throw new Error('Você está offline, tente novamente mais tarde.');
        };

        // Header set Access-Control-Allow-Methods: DELETE, POST, GET, OPTIONS, PUT
        // Header set Access-Control-Allow-Headers: Content-Type, Authorization, X-Requested-With

        if (method === HttpMethod.Get || method === HttpMethod.Delete) {
            return fetch(`${process.env.REACT_APP_API}/${endpoint}${encodeValues ? '?' : ''}${paramUrl}`, {
                method: method,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(data => {
                if(!data.ok) {
                    errors = true;
                }
                if (data.status === 204) return '';
                return data.json();
                
            })
            .then((response) => {
                if(errors) {
                    returnValue = response;
                    if (verifySessionExpired(returnValue)) {
                        setSessaoExpirada(true);
                    }
                    throw new Error(JSON.stringify(returnValue));
                }
                if (response.status === 204) return '';
                return response;
            })
            .catch(err => {throw new Error(err)})
        } 
        else {
            return fetch(`${process.env.REACT_APP_API}/${endpoint}`, {
                method: method,
                headers: 
                    {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    }
                ,
                body: encodeValues ? generateUrlParams(HttpMethod.Get, values) : values
            })
            .then(data => {
                if(!data.ok) {
                    errors = true;
                }
                if (data.status === 204) return '';
                return data.json();
            })
            .then((response) => {
                if(errors) {
                    returnValue = response;
                    if (verifySessionExpired(returnValue)) {
                        setSessaoExpirada(true);
                    }
                    throw new Error(returnValue)
                }
                if (response.status === 204) return '';
                return response;
            })
            .catch(err => {throw new Error(err)})
        };
    };
    return {
        get,
        post,
        put,
        del
    }
}
export default useAPI;