import { Button, Container, Dropdown, Form, InputGroup } from "react-bootstrap"
import useApp from "../../Components/useApp";
import Tabela from "../../Components/table";
import { useOutletContext } from "react-router-dom";
import { IUseProtocolo } from "./Hook/useProtocolo";
import { IProtocolo } from "../../Interfaces";

const ProtocoloLista = () => {
    const App = useApp();
    const Protocolo = useOutletContext<IUseProtocolo>();

    return (
        <Container className="verticalCenterFull">
            <div className="w-100 my-3 d-flex align-items-center justify-content-around">
                <Button variant="dark" onClick={() => window.confirm('Deseja sair do sistema?') ? App.Deslogar() : null}>Deslogar</Button>
                <div className="d-flex" style={{minWidth: '50%'}}>
                    <InputGroup>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                                {Protocolo.Filtro.tipoFiltro}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => Protocolo.setFiltro((prev) => ({...prev, tipoFiltro: 'Numero'}))}>Número</Dropdown.Item>
                                <Dropdown.Item onClick={() => Protocolo.setFiltro((prev) => ({...prev, tipoFiltro: 'Convenio'}))}>Convênio</Dropdown.Item>
                                <Dropdown.Item onClick={() => Protocolo.setFiltro((prev) => ({...prev, tipoFiltro: 'Paciente'}))}>Paciente</Dropdown.Item>
                                <Dropdown.Item onClick={() => Protocolo.setFiltro((prev) => ({...prev, tipoFiltro: 'Data Entrega'}))}>Data de Entrega</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Form.Control type={Protocolo.Filtro.tipoFiltro !== 'Data Entrega' ? "text" : 'date'} value={Protocolo.Filtro.pesquisa} onChange={(e) => Protocolo.setFiltro((prev) => ({...prev, pesquisa: e.target.value}))} />
                        {/* <Button variant="primary">Buscar</Button> */}
                    </InputGroup>
                </div>
                <Button variant="success" onClick={() => Protocolo.Abrir({})}>Cadastrar</Button>
            </div>


            <div className="tabelaWrapper">
                <Tabela dados={Protocolo.Lista} colunas={Protocolo.Grid} handleClick={(e) => Protocolo.Abrir(e as IProtocolo)} />
            </div>
        </Container>
    )
}

export default ProtocoloLista;