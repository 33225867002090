import { Outlet, useParams } from "react-router-dom";
import useProtocolo from "./Hook/useProtocolo";

const Protocolo = () => {
    const { cdProtocolo } = useParams();
    const Protocolo = useProtocolo(cdProtocolo);
    
    return (
        <Outlet context={Protocolo} />
    )
}

export default Protocolo;