import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import { DeepPartial, IProtocolo, IProtocolos } from "../Interfaces";
import useAPI from "../Components/useAPI";
import useControllers from "../Components/useControllers";

const useProtocoloController = (updateQueryKey: string, isArray: boolean = true, enableQuery: boolean = false) : IUseProtocoloController => {
    const API = useAPI();
    const queryClient = useQueryClient();
    const { Update, Delete, Create } = useControllers();

    const primaryKey = 'CD_PROTOCOLO';
    // const foreignKey = 'CELULAR';
    const endpoint = 'protocolos';

    const mutDeletar = useMutation(
        (data : DeepPartial<IProtocolo>) => {
            return API.del(`${endpoint}/deletar/${data[primaryKey]}`, "")
        }, {
            onSuccess: (ret: any, data: DeepPartial<IProtocolo>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IProtocolos>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Delete(oldData, data);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutAlterar = useMutation(
        (data : DeepPartial<IProtocolo>) => {
            return API.put(`${endpoint}/alterar/${data[primaryKey]}`, data)
        }, {
            onSuccess: (ret: DeepPartial<IProtocolo>, data: DeepPartial<IProtocolo>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IProtocolos>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Update(oldData, {CD_PEDIDO: data[primaryKey], ...ret}, primaryKey, isArray);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutCadastrar = useMutation(
        (data : DeepPartial<IProtocolo>) => {
            return API.post(`${endpoint}/cadastrar`, data)
        }, {
            onSuccess: (ret: any, data: DeepPartial<IProtocolo>) => {
                if (!!updateQueryKey) {
                    queryClient.setQueryData<IProtocolos>(
                        updateQueryKey,
                        (oldData: any) => {
                            if (oldData) {
                                return Create(oldData, ret['sucesso']);
                            }
                            return oldData;
                        }
                    );
                };
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const mutBuscar = useMutation(
        (data : DeepPartial<IProtocolo>) => 
            API.get(`${endpoint}/buscar/${data[primaryKey]}`, "")
        , {
            onSuccess: (ret: IProtocolo) => {
                // setItem(ret);
            },
            onError: (error: any) => {
                alert(error)
            }
    });

    const qryListar = useQuery<IProtocolos>(
        updateQueryKey!,
        (
            () => API.get(`${endpoint}/listarTodos/${localStorage.getItem("celular")}`, '', false)
        ),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 3,
            enabled: enableQuery,
            refetchInterval: 600000,
            refetchIntervalInBackground: false,
        }
    );    

    return {
        Deletar: mutDeletar,
        Alterar: mutAlterar,
        Cadastrar: mutCadastrar,
        Buscar: mutBuscar,
        Listar: qryListar,
    }
}

export interface IUseProtocoloController {
    Deletar     : UseMutationResult<any, any, DeepPartial<IProtocolo>, unknown>;
    Alterar     : UseMutationResult<any, any, DeepPartial<IProtocolo>, unknown>;
    Cadastrar   : UseMutationResult<any, any, DeepPartial<IProtocolo>, unknown>;
    Buscar      : UseMutationResult<IProtocolo, any, DeepPartial<IProtocolo>, unknown>
    Listar      : UseQueryResult<IProtocolos, unknown>;
}

export default useProtocoloController;