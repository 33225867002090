import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import { IProtocolo } from "../Interfaces";

import {ArialRegularBase64, ArialBoldBase64, ArialBoldItalicBase64 , ArialItalicBase64} from "./fonts/arial";
import useMask from "../Components/useMask";

const Report_CapaPendencia = (Protocolo: IProtocolo) => {
    const formatter = useMask();
    pdfMake.vfs = {
        ...pdfFonts.pdfMake.vfs,
        "ArialRegularBase64": ArialRegularBase64,
        "ArialBoldBase64": ArialBoldBase64,
        "ArialItalicBase64": ArialItalicBase64,
        "ArialBoldItalicBase64": ArialBoldItalicBase64
    };

    pdfMake.fonts = {
        Arial: {
            normal: 'ArialRegularBase64',
            bold: 'ArialBoldBase64',
            italics: 'ArialItalicBase64',
            bolditalics: 'ArialBoldItalicBase64'
        },
    };

    const title     : Content = [
        {
            columns: [
                {
                    text: `PACIENTE: ${Protocolo.PACIENTE}`,
                    alignment: 'left',
                    width: '*'
                },
                {
                    text : `Nº ${Protocolo.NUMERO}`,
                    alignment: 'right',
                    width: '100'
                }
            ],
            fontSize: 12, 
            bold: true,
            alignment: 'left',
            margin: [50, 50, 50, 0],
            lineHeight: 2
        },
        {
            text: `►     ${Protocolo.PENDENCIA}`,
            fontSize: 12, 
            bold: true,
            alignment: 'left',
            margin: [50, 0, 50, 0],
            lineHeight: 2
        },
        {
            text: `CONV. ${Protocolo.CONVENIO}          ${formatter.setDate(new Date(Protocolo.DT_ENTRADA), {dateStyle: "short"})}          ALTA            ${formatter.setDate(new Date(Protocolo.DT_SAIDA), {dateStyle: "short"})}`,
            fontSize: 11, 
            bold: false,        
            alignment: 'left',
            margin: [50, 0, 50, 0],
            lineHeight: 2
        },
        {
            text: `DATA DA ENTREGA:     ${formatter.setDate(new Date(Protocolo.DT_ENTREGA!), {dateStyle: "short"})}`,
            fontSize: 10, 
            bold: false,        
            alignment: 'left',
            margin: [50, 0, 50, 0],
            lineHeight: 1
        },
        {
            canvas: [
                {
                    type: 'line',
                    x1: 0,    // Posição inicial X da linha
                    y1: 5,    // Posição inicial Y da linha
                    x2: 595-2*50, // Largura da linha (595 é a largura de uma página A4, menos as margens padrão)
                    y2: 5,    // Posição final Y da linha (mesma que y1 para uma linha reta)
                    lineWidth: 1 // Espessura da linha
                }
            ],
            margin: [50, 0, 50, 20],
        }
    ];

    const dados   : Content = [
        {
            text: `PELO FATURAMENTO - VERIFICAR SEMANALMENTE`,
            bold: true,
            italics: true,
            alignment: 'center',
            fontSize: 12,
            marginTop: 25
        },
        {
            text: ' '
        },
        {
            text: `CHECADO COM A AUTORIZAÇÃO EM:________________________________________`,
            fontSize: 12,
            alignment: 'center',
            lineHeight: 2
        },
        {
            text: `SE AINDA EM PENDÊNCIA DESCREVA:_______________________________________`,
            fontSize: 12,
            alignment: 'center',
            lineHeight: 2
        },
        {
            text: `COLABORADOR( LEGÍVEL ):________________________________________________`,
            fontSize: 12,
            alignment: 'center',
            lineHeight: 2
        },
        {
            text: ' '
        },
        {
            text: `CHECADO COM A AUTORIZAÇÃO EM:________________________________________`,
            fontSize: 12,
            alignment: 'center',
            lineHeight: 2
        },
        {
            text: `SE AINDA EM PENDÊNCIA DESCREVA:_______________________________________`,
            fontSize: 12,
            alignment: 'center',
            lineHeight: 2
        },
        {
            text: `COLABORADOR( LEGÍVEL ):________________________________________________`,
            fontSize: 12,
            alignment: 'center',
            lineHeight: 2
        },
        {
            text: ' '
        },
        {
            text: `CHECADO COM A AUTORIZAÇÃO EM:________________________________________`,
            fontSize: 12,
            alignment: 'center',
            lineHeight: 2
        },
        {
            text: `SE AINDA EM PENDÊNCIA DESCREVA:_______________________________________`,
            fontSize: 12,
            alignment: 'center',
            lineHeight: 2
        },
        {
            text: `COLABORADOR( LEGÍVEL ):________________________________________________`,
            fontSize: 12,
            alignment: 'center',
            lineHeight: 2
        },
        {
            text: ' '
        },
        {
            text: ' '
        },
        {
            text: ' '
        },
        {
            text: `SOLUÇÃO FINAL:_________________________________________________________`,
            fontSize: 12,
            alignment: 'center',
            lineHeight: 2
        },
    ];    

    const docDefinitions : TDocumentDefinitions = {
        pageSize: "A4",
        pageMargins: [50, 200, 50 , 50], //left top right bottom
        
        defaultStyle: {
            font: 'Arial',
            lineHeight: 1.5,
            characterSpacing: 0.15
        },
        header: [title],
        content: [dados]
    }

    pdfMake.createPdf(docDefinitions).open();
}

export default Report_CapaPendencia;